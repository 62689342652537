import './App.css';
import { useEffect, useState } from 'react';
import ImageUploader from './components/imager/Imager';
import {SampleDigitalECG} from './samples.js';
import { Omi } from './components/omi/Omi.js';
import { Diagnoses } from './components/diagnoses/Diagnoses.js';
import { DemoStep } from './components/demostep/DemoStep.js';
import { API_URLS, LAMBDA_URL } from './constants.js';

function App() {
  const [apiKey, setAPIKey] = useState(window.location.pathname.substring(1));
  const [quotas, setQuotas] = useState(null);

  const [currentImage, setCurrentImage] = useState(null);
  const [digitizedECG, setDigitizedECG] = useState(null);
  const [visualization, setVisualization] = useState(null);
  const [omiDiagnosis, setOmiDiagnosis] = useState(null);
  const [allDiagnoses, setAllDiagnoses] = useState(null);

  const [defaultStates, setDefaultStates] = useState({
    "digitization": "future",
    "visualization": "future",
    "omi": "future",
    "diagnoses": "future",
    "print": "future",
    "reload": "future",
  });

  const [digitizationFailure, setDigitizationFailure] = useState(null);
  const [visualizationFailure, setVisualizationFailure] = useState(null);
  const [omiFailure, setOmiFailure] = useState(null);
  const [diagnosesFailure, setDiagnosesFailure] = useState(null);
  const [expiredKey, setExpiredKey] = useState(false);
  const [apiURL, setAPIURL] = useState(null);

  function forwardImageHere(image) {
    setCurrentImage(image);
    const temp = {...defaultStates};
    temp.digitization = "ready"
    setDefaultStates(temp);
  }

  async function digitzeECG() {
    const temp = {...defaultStates};
    temp.digitization = "pending"
    setDefaultStates(temp);
    setDigitizationFailure(null);
    setDigitizedECG(null);
    // POST the image as form data
    let formData = new FormData();

    formData.append("image", currentImage);
    formData.append("number_of_photos", 1);
    formData.append("index_of_photo", 0);
    formData.append("paper_speed", 25);
    formData.append("voltage_gain", 10);
    let res = await fetch(`${apiURL}/v0/ecg/digitization/v2`, {
      method: "POST",
      body: formData,
      headers: {
        "PM-API-Key": apiKey,
      }
    });
    const newDefaultStates = {...defaultStates};
    newDefaultStates.digitization = "failed";
    if (res.status == 200) {
      const j = await res.json();
      if (j.output.success == true) {
        newDefaultStates.digitization = "success";
        newDefaultStates.visualization = "ready";
        setDigitizedECG(j);
      } else {
        setDigitizationFailure(
          <div class="error">
            <ul>
              {j.output.error_codes.image_too_small ? <li>The minimum width and height of the image is 128 pixels.</li> : ""}
              {j.output.error_codes.not_enough_points ? <li>No ECG paper was detected on the image. Please ensure good lighting conditions and sharp image and try again.</li> : ""}
              {j.output.error_codes.bad_grid_angle ? <li>Detected points do not seem to form a regular ECG grid. Please ensure good lighting conditions and sharp image and try again.</li> : ""}
              {j.output.error_codes.not_enough_squares ? <li>Detected points do not seem to form a regular ECG grid. Please ensure good lighting conditions and sharp image and try again. </li>: ""}
              {j.output.error_codes.too_many_points ? <li>Ensure that the small and large squares of the ECG grid are clearly distinguishable and try again.</li> : ""}
              {j.output.error_codes.wrong_rotation ? <li>The image is incorrectly rotated. Please try again and make sure that the leads on the image are in a horizontal position.</li> : ""}
              {j.output.error_codes.wrong_layout ? <li>This ECG layout might not be supported. Please try again.</li> : ""}
              {j.output.error_codes.wrong_lead_number ? <li>The number of leads in the ECG does not correspond to any supported ECG format. Check if all leads are clearly visible.</li> : ""}
              {j.output.error_codes.leads_too_short ? <li>All ECG leads should have at least 1.5 seconds (1500 ms).</li> : ""}
            </ul>
          </div>
        );
      }
    } else if (res.status == 401) {
      setDigitizationFailure("Your API Key seems to be expired. Get in touch with mmartonak@powerfulmedical.com to obtain a new key!");
    } else if (res.status == 402) {
      setDigitizationFailure("Your API Key seems to be a very old demo key that doesn't have digitization enabled. Get in touch with mmartonak@powerfulmedical.com to obtain a new key!");
    } else if (res.status == 413) {
      setDigitizationFailure("The ECG Image is too large!");
    } else {
      setDigitizationFailure(<div class="error">An Unexpected error occurred: {res.status}<br/>{await res.text()}</div>);
    }
    setDefaultStates(newDefaultStates);
    console.log(res);
  }

  function reLeader(digizedLead){
    return {
      location_override: {
        x: digizedLead.plot_metadata.x_start,
        y: digizedLead.plot_metadata.y_baseline,
      },
      sampling_frequency: digizedLead.sampling_frequency,
      ys: digizedLead.signal,
      lead_name: digizedLead.lead_name,
    }
  }

  async function visualizeECG() {
    const temp = {...defaultStates};
    temp.visualization = "pending"
    setDefaultStates(temp);
    const ecg = digitizedECG || SampleDigitalECG;
    const payload = {
      "strategy": "digitized",
      "rows": ecg.output.metadata.ecg_metadata.ecg_layout.split("x")[0],
      "mm_per_mv": ecg.output.metadata.ecg_metadata.voltage_gain,
      "mm_per_s": ecg.output.metadata.ecg_metadata.paper_speed,
      "leads": ecg.output.leads.map(reLeader),
      "rhythm_leads": ecg.output.rhythm_leads.map(reLeader),
      "cabrera": ecg.output.metadata.ecg_metadata.lead_order == "cabrera",
    }
    console.log(payload);
    let res = await fetch(
      LAMBDA_URL,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          "Accept": "image/svg+xml",
        }
      }
    );
    const newDefaultStates = {...defaultStates};
    newDefaultStates.visualization = "failed";
    if (res.status == 200 && res.headers.get("Content-Type") == "image/svg+xml") {
      const url = URL.createObjectURL(await res.blob());
      setVisualization(url);
      newDefaultStates.visualization = "success";
      newDefaultStates.omi = "ready";
    } else {
      setVisualizationFailure(<div class="error">An Unexpected error occurred: {res.status}<br/>{await res.text()}</div>);
    }
    setDefaultStates(newDefaultStates);
  }

  async function diagnoseOMI() {
    const temp = {...defaultStates};
    temp.omi = "pending"
    setDefaultStates(temp);
    const ecg = digitizedECG || SampleDigitalECG;
    function omiReleader(digitizedLead) {
      return {
        "signal": digitizedLead.signal,
        "sampling_frequency": digitizedLead.sampling_frequency,
        "lead_name": digitizedLead.lead_name,
      }
    }
    const payload = {
      "leads": ecg.output.leads.map(omiReleader),
    }
    console.log(payload);
    let res = await fetch(
      `${apiURL}/v0/ecg/omi/v1`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "PM-API-Key": apiKey,
        }
      }
    );
    const newDefaultStates = {...defaultStates};
    newDefaultStates.omi = "failed";
    if (res.status == 200) {

      const j = await res.json();
      if (j.success == true) {
        newDefaultStates.omi = "success";
        newDefaultStates.diagnoses = "ready";
        setOmiDiagnosis(j);
      } else {
        setOmiFailure(
          <div class="error">
            <ul>
            {j.error_codes.missing_leads ? <li>At least one of the following leads is missing MDC_ECG_LEAD_I, MDC_ECG_LEAD_II, MDC_ECG_LEAD_III, MDC_ECG_LEAD_aVR, MDC_ECG_LEAD_aVL, MDC_ECG_LEAD_aVF, MDC_ECG_LEAD_V1, MDC_ECG_LEAD_V2, MDC_ECG_LEAD_V3, MDC_ECG_LEAD_V4, MDC_ECG_LEAD_V5, MDC_ECG_LEAD_V6.</li> : "" }
            {j.error_codes.too_many_gaps_in_leads ? <li>The provided leads have too many missing parts. Please ensure the input ECG is valid.</li> : "" }
            {j.error_codes.low_sampling_frequency ? <li>One or more leads have a too low sampling frequency. All input leads must have a sampling frequency of at least 250Hz.</li> : "" }
            {j.error_codes.leads_too_short ? <li>All input leads should have at least 2500ms.</li> : "" }
            {j.error_codes.leads_too_long ? <li>All input lead signals should have at most 20000 data points.</li> : "" }
            {j.error_codes.duplicated_leads ? <li>At least one of the following leads is duplicated MDC_ECG_LEAD_I, MDC_ECG_LEAD_II, MDC_ECG_LEAD_III, MDC_ECG_LEAD_aVR, MDC_ECG_LEAD_aVL, MDC_ECG_LEAD_aVF, MDC_ECG_LEAD_V1, MDC_ECG_LEAD_V2, MDC_ECG_LEAD_V3, MDC_ECG_LEAD_V4, MDC_ECG_LEAD_V5, MDC_ECG_LEAD_V6.</li> : "" }
            </ul>
          </div>
        );
      }
    } else if (res.status == 401) {
      setOmiFailure("Your API Key seems to be expired. Get in touch with mmartonak@powerfulmedical.com to obtain a new key!");
    } else if (res.status == 402) {
      setOmiFailure("Your API Key seems to be a very old demo key that doesn't have omi enabled. Get in touch with mmartonak@powerfulmedical.com to obtain a new key!");
    } else if (res.status == 413) {
      setOmiFailure("The ECG is too large!");
    } else {
      setOmiFailure(<div class="error">An Unexpected error occurred: {res.status}<br/>{await res.text()}</div>);
    }
    setDefaultStates(newDefaultStates);
  }

  async function diagnose38() {
    const temp = {...defaultStates};
    temp.diagnoses = "pending"
    setDefaultStates(temp);
    const ecg = digitizedECG || SampleDigitalECG;
    function releader38(digitizedLead) {
      return {
        "signal": digitizedLead.signal,
        "sampling_frequency": digitizedLead.sampling_frequency,
        "lead_name": digitizedLead.lead_name,
      }
    }
    const payload = {
      "success": true,
      "version": "N/A",
      "model_identifier": ecg.powerfulmedical_system_id,
      "leads": ecg.output.leads.map(releader38),
    }
    console.log(payload);
    let res = await fetch(
      `${apiURL}/v0/ecg/diagnosis/v2`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "PM-API-Key": apiKey,
        }
      }
    );
    const newDefaultStates = {...defaultStates};
    newDefaultStates.diagnoses = "failed";
    if (res.status == 200) {
      const j = await res.json();
      if (j.failure == null) {
        newDefaultStates.diagnoses = "success";
        newDefaultStates.print = "ready";
        setAllDiagnoses(j.output);
      } else {
        setDiagnosesFailure(
          <div class="error">
            <ul>
            {j.error_codes.outlier_measurements ? <li>There seem to be significant outliers in the provided signal. Please check the signal is clean.</li> : "" }
            {j.error_codes.leads_too_short ? <li>All input leads should have at least 2500ms.</li> : "" }
            {j.error_codes.leads_too_long ? <li>All input lead signals should have at most 20000 data points.</li> : "" }
            {j.error_codes.insufficient_signal ? <li>There is not enough signal data present in the input do perform diagnostics accurately. Check your signal for gaps or length.</li> : "" }
            </ul>
          </div>
        );
      }
    } else if (res.status == 401) {
      setDiagnosesFailure("Your API Key seems to be expired. Get in touch with mmartonak@powerfulmedical.com to obtain a new key!");
    } else if (res.status == 402) {
      setDiagnosesFailure("Your API Key seems to be a very old demo key that doesn't have diagnostics enabled. Get in touch with mmartonak@powerfulmedical.com to obtain a new key!");
    } else if (res.status == 413) {
      setDiagnosesFailure("The ECG is too large!");
    } else {
      setDiagnosesFailure(<div class="error">An Unexpected error occurred: {res.status}<br/>{await res.text()}</div>);
    }
    setDefaultStates(newDefaultStates);
    console.log(res);
  }

  useEffect(() => {(async () => {
    var found = false;
    for (const possibleAPIURL of API_URLS) {
      try{
        let res = await fetch(`${possibleAPIURL}/v0/api_key_metadata`, {
          method: "GET",
          headers: {
            "PM-API-Key": apiKey,
          }
        });
        if (res.status < 300) {
          console.log(res);
          setAPIURL(possibleAPIURL);
          setQuotas(await res.json());
          found = true;
          break;
        }
      } catch {
  
      }
    }
    if (!found) {
      setExpiredKey(true);
    }
  })()}, []);

  return <>
  <div className='toosmall'>
    <h1>Screen too small for this demo!</h1>
    <p>This demo requires a wider screen to see all the information and interactive documentation. Please open this on your computer!</p>
  </div>
  <div className="App">
    <div class="info">
      <h1>Powerful Medical<br/>AI API Showcase</h1>
      <h2>Your API Key</h2>
      <p>This showcase uses an API Key to communicate with the AI API. This key was specifically made for you! Here is the key:</p>
      <div class="apikey">{apiKey}</div>
      <div>
        {quotas != null ? <>
          <h3>API Key Quotas</h3>
          <p>Expiration: {quotas.expiration}</p>
          <table>
            <thead>
              <tr>
                <td><b>Feature</b></td><td><b>Quota</b></td>
              </tr>
            </thead>
            <tbody>
              {Object.keys(quotas.limits).map((limit) => {
                return <>
                  <tr><td>{limit}</td><td>{quotas.limits[limit].remaining_quota}</td></tr>
                </>;
              })}
            </tbody>
          </table>
          <p>This API Key is for the following API: <a href={`${apiURL}/v0/docs`} target="_blank">{apiURL}/v0/docs</a></p>
        </> : null}
      </div>
      <h3>Documentation</h3>
      <p>
        This Demo website <b>directly</b> uses the Powerful Medical AI API.
      </p>
      <p>
        Everything this website does can be done in your own product by calling the API.
      </p>
      <p>
        An interactive documentation page for the API can be found here:
      </p>
      <ul>
        <li><a href="https://eu-west-1.pm-api.com/v0/docs" target="_blank">EU API</a></li>
        <li><a href="https://eu-west-1.pm-api.com/v0/docs" target="_blank">US API</a></li>
      </ul>
    </div>
    <div>
      <div className="pageSlot">
        <div class="page">
          <div className="preamble">
            <div className="title">ECG Report</div>
            <div className="logo"><img src="/logo.png"/></div>
          </div>
          {visualization == null && digitizedECG == null && quotas != null ? <ImageUploader forwardImageHere={forwardImageHere}/> : null}
          { quotas == null && !expiredKey ? <div
            style={{
              margin: "5cm auto 20px auto",
              width: "70%",
              fontSize: "2em",
              textAlign: "center",
            }}
          >Checking your API Key Quota... Please wait.</div> : null}
          { expiredKey ? <div style={{
            backgroundColor: "#ff3333",
            color: "white",
            fontWeight: "bold",
            padding: "1cm",
            margin: "5cm auto 20px auto",
            width: "100%",
            fontSize: "1.5em",
            textAlign: "center",
            boxSizing: "border-box",
          }}>
            <p>Your API Key is expired or invalid!</p>
            <p>Get in touch with<br/>partnerships@powerfulmedical.com<br/>for a new key</p>
          </div> : null}
          {visualization == null && digitizedECG != null ? <div style={{
            fontFamily: 'monospace',
            maxWidth: "100%",
            maxHeight: "40%",
            overflowY: "scroll",
          }}>
            {JSON.stringify(digitizedECG)}
          </div> : ""}
          {visualization != null ? <img style={{
            maxWidth: "100%",
            maxHeight: "40%",
            objectFit: "contain",
          }} src={visualization}/> : ""}
          <Omi prediction={omiDiagnosis}/>
          <Diagnoses prediction={allDiagnoses}/>
        </div>
      </div>
    </div>
    <div className="docs">
      <h1>Interactive Walkthrough</h1>
      {<DemoStep
        runName="Digitization"
        onButtonClick={digitzeECG}
        title="Digitization"
        state={defaultStates.digitization}
        description="Digitization allows you to extract Digital Signals from a photograph of an ECG. Choose any ECG you want and drag it into the blue square to get started!"
        curl={<>
          curl -X 'POST' \<br/>
          'https://eu-west-1.pm-api.com/v0/ecg/digitization/v2' \<br/>
          -H 'accept: application/json' \<br/>
          -H 'PM-API-Key: {apiKey}' \<br/>
          -H 'Content-Type: multipart/form-data' \<br/>
          -F 'image=@ecg_image.jpg;type=image/jpeg' \<br/>
          -F 'number_of_photos=1' \<br/>
          -F 'index_of_photo=0' \<br/>
          -F 'paper_speed=25' \<br/>
          -F 'voltage_gain=10'
        </>}
        failureContent={digitizationFailure}
      />}
      {<DemoStep
        runName="Visualization"
        onButtonClick={visualizeECG}
        title="Visualization"
        state={defaultStates.visualization}
        description="You can see the signals our AI extracted from the photograph in JSON format on the page. Now, let's visualize the ECG from the digitized signals. You can do this however you want, we have a simple visualization available for demo purposes. Click the button below to run it."
        failureContent={visualizationFailure}
      />}
      {<DemoStep
        runName="OMI Diagnosis"
        onButtonClick={diagnoseOMI}
        title="OMI Diagnosis"
        state={defaultStates.omi}
        description="With the signals digitized we can now run them through our flagship infarction detection AI, called the OMI AI (also known as the Queen of Hearts in the cardiology community)."
        curl={<>
          curl 'https://eu-west-1.pm-api.com/v0/ecg/omi/v1' \<br/>
          -H 'accept: application/json' \<br/>
          -H 'PM-API-Key: {apiKey}' \<br/>
          -d '@digital_signals.json'
        </>}
        failureContent={omiFailure}
      />}
      {<DemoStep
        runName="Core Diagnostics"
        onButtonClick={diagnose38}
        title="Core Diagnostics"
        state={defaultStates.diagnoses}
        description="To gain a full diagnostic report on the ECG you can use our diagnostics API endpoint, which checks the ECG for 38 conditions and computes 10 measurement parameters."
        curl={<>
          curl 'https://eu-west-1.pm-api.com/v0/ecg/diagnosis/v1' \<br/>
          -H 'accept: application/json' \<br/>
          -H 'PM-API-Key: {apiKey}' \<br/>
          -d '@digital_signals.json'
        </>}
        failureContent={diagnosesFailure}
      />}
      {<DemoStep
        runName="Print as PDF"
        onButtonClick={() => {window.print(); const temp = {...defaultStates}; temp.print = "success"; temp.reload = "ready"; setDefaultStates(temp);}}
        title="Print as PDF"
        state={defaultStates.print}
        description="Now that the Report is finalized, you can print it as a PDF:"
        curl={null}
        failureContent={null}
      />}
      {<DemoStep
        runName="Start over to make a new report!"
        onButtonClick={() => {window.location = window.location;}}
        title="Start over to make a new report!"
        state={defaultStates.reload}
        description="That's it! Now You have finished your first report. You can now reload the page to create another one. :)"
        curl={null}
        failureContent={null}
      />}
    </div>
  </div>
  </>;
}

export default App;
