 export const API_URLS = [
     "https://eu-west-1.pm-api.com",
     "https://us-east-1.pm-api.com",
 ]
 export const LAMBDA_URL = "https://oa2l63ju2tp42ktaqwaaj24ium0duije.lambda-url.eu-west-1.on.aws/"

// export const API_URLS = [
//     "/eu",
//     "/us",
// ]
// export const LAMBDA_URL = "/lambda"
