import {diagnosisNames} from "./mappings.js";
import "./Diagnoses.css";

export function Diagnoses({prediction = null}) {
    let result = prediction;
    if (prediction == null) return "";
    let rows = [];
    for (let d of result.core_diagnoses) {
        if (d.is_present != true) continue;

        rows.push(<tr>
            <td>{diagnosisNames[d.code]}</td>
            <td>Prediction: {(d.prediction * 100).toFixed(2)}%.</td>
        </tr>);
    }
    const diagnosesTable = <div className="diagnoses">
        <h3>Detected Diagnoses</h3>
        <table>
        <thead>
            <tr>
                <td>Detected Diagnosis</td>
                <td>Prediction</td>
            </tr>
        </thead>
        <tbody>
            {rows}
        </tbody>
    </table></div>
    const measurementsTable = <div className="parameters">
        <h3>Measurements</h3>
        <table>
        <tbody>
            <tr><td>BPM:</td><td>{result.measurements.bpm}</td></tr>
            <tr><td>PR Interval:</td><td>{result.measurements.pr_interval}</td></tr>
            <tr><td>QT Interval:</td><td>{result.measurements.qt_interval}</td></tr>
            <tr><td>RR Interval:</td><td>{result.measurements.rr_interval}</td></tr>
            <tr><td>PP Interval:</td><td>{result.measurements.pp_interval}</td></tr>
            <tr><td>QRS Complex:</td><td>{result.measurements.qrs_complex}</td></tr>
            <tr><td>P Wave:</td><td>{result.measurements.p_wave}</td></tr>
            <tr><td>QT Corrected:</td><td>{result.measurements.qt_corrected}</td></tr>
            <tr><td>Axis:</td><td>{result.measurements.axis}</td></tr>
        </tbody>
    </table></div>
    return <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    }} className="diagnosticsSection">{diagnosesTable}{measurementsTable}</div>
}